import * as React from 'react';
import {useEffect, useState} from 'react';
import PlannedExpenseDropdownDto from "../../dto/PlannedExpenseDropdownDto";
import {getBudgetsSummaryByDates, getRealExpensesPaged, getRealExpensesSummary} from "../../api/api";
import {CircularProgress, Grid} from "@mui/material";
import RealExpenseList from "../../components/real-expense-list/RealExpenseList";
import RealExpenseDto from "../../dto/RealExpenseDto";
import PageableDto from "../../dto/PageableDto";
import {format} from "date-fns";
import {defaultPage, defaultPageSize} from "../../constants/pagination";
import {Link} from "react-router-dom";
import RealExpenseSummaryDto from "../../dto/RealExpenseSummaryDto";
import Button from "@mui/material/Button";
import {Timeout} from "react-number-format/types/types";
import BudgetSummary from "../../components/budget-summary/BudgetSummary";
import {useTranslation} from "react-i18next";
import RealExpensesByCategory from "../../components/real-expenses-by-category/RealExpensesByCategory";
import {LoadingItem} from "../../components/loading-item/LoadingItem";
import FormItem from "../../components/form-item/FormItem";
import useAppContext from "../../hooks/use-app-context";


function Home() {
    const {t, i18n} = useTranslation();
    const {
        selectedBudget,
        dateFrom,
        dateTo,
        expenseCategories,
        onSaveExpenseCategory
    } = useAppContext();
    const [plannedExpenses, setPlannedExpenses] = useState<PlannedExpenseDropdownDto[]>([]);
    const [realExpensesLoading, setRealExpensesLoading] = useState(false);
    const emptyPageable = {
        content: [],
        totalPages: 0,
        totalElements: 0,
        size: 0,
        number: 0
    } as PageableDto<RealExpenseDto[]>;
    const [pageable, setPageable] = useState<PageableDto<RealExpenseDto[]>>(emptyPageable);
    const [page, setPage] = useState(defaultPage);
    const [pageSize, setPageSize] = useState(defaultPageSize);
    const [month, setMonth] = useState('');
    const [searchQuery, setSearchQuery] = useState('');
    const emptyExpensesSummary = {
        totalAmount: "0.00"
    } as RealExpenseSummaryDto;
    const [expensesSummary, setExpensesSummary] = useState<RealExpenseSummaryDto>(emptyExpensesSummary);
    const [loadTimeoutId, setLoadTimeoutId] = useState<Timeout | undefined>(undefined);
    const emptyBudgetSummary = {
        budgetBalance: "0.00",
        plannedExpensesBalance: "0.00",
        incomeAmount: "0.00",
        plannedExpensesAmount: "0.00",
        unplannedExpensesAmount: "0.00",
        realExpensesAmount: "0.00"
    };
    const [budgetSummary, setBudgetSummary] = useState(emptyBudgetSummary);

    const delayLoadRealExpenses = (query: string) => {
        if (typeof loadTimeoutId === 'number') {
            clearTimeout(loadTimeoutId);
            setLoadTimeoutId(undefined);
        }

        const newId = setTimeout(() => {
            loadRealExpenses(defaultPage, defaultPageSize, query).then();
        }, 1500);
        setLoadTimeoutId(newId);
    };

    const loadBudgetSummary = () => {
        getBudgetsSummaryByDates(selectedBudget, dateFrom, dateTo).then(result => {
            setBudgetSummary(result);
        });
    };

    const loadRealExpenses = async (page: number, size: number, query: string) => {
        setRealExpensesLoading(true);
        await getRealExpensesPaged(
            selectedBudget, page, size, dateFrom, dateTo, query
        ).then(response => {
            setPageable(response);
            setRealExpensesLoading(false);
        });
    };

    const onChangeQuery = (event: any) => {
        let cleanedValue = event.target.value.replace(/[^\w\s.,]/g, "");
        cleanedValue = cleanedValue.replace(",", ".");
        setSearchQuery(cleanedValue);
        delayLoadRealExpenses(cleanedValue);
    };

    const loadRealExpensesSummary = () => {
        getRealExpensesSummary(selectedBudget, dateFrom, dateTo).then(results => {
            setExpensesSummary(results);
        });
    };

    useEffect(() => {
        if (selectedBudget === undefined || selectedBudget === '') {
            return;
        }

        if (dateFrom === undefined || dateFrom === '') {
            return;
        }

        loadRealExpenses(defaultPage, defaultPageSize, '').then();
        const date = new Date(dateFrom);
        setMonth(format(date, 'MMMM yyyy'));
        loadRealExpensesSummary();
        loadBudgetSummary();
    }, [selectedBudget, dateFrom, dateTo, expenseCategories]);

    return (
        <div>
            <Grid container spacing={1}>
                <Grid item xs={12} lg={12}>
                    <FormItem>
                        <h2>{t('Real expenses')} ({month})</h2>
                        <Button
                            component={Link}
                            to="/create-expense"
                            variant="contained">
                            {t('Create new expense')}
                        </Button>
                    </FormItem>
                </Grid>
                <Grid item xs={12} lg={2}>
                    <FormItem>
                        <h3>{t('Your budget')}</h3>
                        <BudgetSummary summary={budgetSummary}/>
                    </FormItem>
                </Grid>
                <Grid item xs={12} lg={3}>
                    <FormItem>
                        <RealExpensesByCategory
                            expenseCategories={expenseCategories}
                            expensesSummary={expensesSummary}
                        />
                    </FormItem>
                </Grid>
                <Grid item xs={12} lg={12}>
                    <FormItem>
                        <h3>{t('Real expenses list')}</h3>
                        {!realExpensesLoading ? <RealExpenseList
                            pageable={pageable}
                            categories={expenseCategories}
                            plannedExpenses={plannedExpenses}
                            query={searchQuery}
                            page={page}
                            pageSize={pageSize}
                            setPage={setPage}
                            setPageSize={setPageSize}
                            loadData={loadRealExpenses}
                            onChangeQuery={onChangeQuery}
                            onSaveExpenseCategory={onSaveExpenseCategory}
                        /> : <LoadingItem><CircularProgress/></LoadingItem>}
                    </FormItem>
                </Grid>
            </Grid>
        </div>
    );
}

export default Home;