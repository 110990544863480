import {Outlet} from "react-router-dom";
import Navbar from "./components/navbar/Navbar";
import Login from "./pages/login/Login";
import Filters from "./components/filters/Filters";
import AppLoader from "./components/app-loader/AppLoader";
import {Alert} from "@mui/material";
import Redirector from "./components/redirector/Redirector";
import useAppContext from "./hooks/use-app-context";

function Layout() {
    const {isLoggedIn, isOnline, appLoading} = useAppContext();

    return (
        <>
            <Navbar/>
            {!isOnline && !appLoading ?
                <Alert severity="error">Service is OFFLINE! We should come back after a few minutes.</Alert> : null}
            {isOnline && isLoggedIn ? <Filters/> : null}
            {isOnline && isLoggedIn ? <Outlet/> : null}
            {isOnline && !isLoggedIn ? <Login/> : null}
            <AppLoader/>
            <Redirector/>
        </>
    )
}

export default Layout;