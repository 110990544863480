import * as React from 'react';
import {useEffect, useState} from 'react';
import RealExpenseRequestDto from "../../dto/RealExpenseRequestDto";
import RealExpenseForm from "../../components/real-expense-form/RealExpenseForm";
import PlannedExpenseDropdownDto from "../../dto/PlannedExpenseDropdownDto";
import {getPlannedExpensesDropdown, saveRealExpense} from "../../api/api";
import {useLocation, useNavigate} from "react-router-dom";
import {Breadcrumbs, Grid} from "@mui/material";
import Typography from "@mui/material/Typography";
import Link from '@mui/material/Link';
import {useTranslation} from "react-i18next";
import ContentItem from "../../components/content-item/ContentItem";
import useAppContext from "../../hooks/use-app-context";

function useQuery() {
    const {search} = useLocation();

    return React.useMemo(() => new URLSearchParams(search), [search]);
}

function RealExpenseCreate() {
    const {t} = useTranslation();
    const query = useQuery();
    const {
        selectedBudget,
        dateFrom,
        dateTo,
        expenseCategories,
        onSaveExpenseCategory
    } = useAppContext();
    const [plannedExpenses, setPlannedExpenses] = useState<PlannedExpenseDropdownDto[]>([]);
    const [initialPlannedExpense, setInitialPlannedExpense] = useState<PlannedExpenseDropdownDto | null>(null);
    const plannedExpenseBusinessKey: string | null = query.get("plannedExpenseId") !== null
        ? query.get("plannedExpenseId")
        : null;
    const navigate = useNavigate();

    const onSaveRealExpense = (data: RealExpenseRequestDto) => {
        saveRealExpense(data).then(() => {
            if (plannedExpenseBusinessKey !== null) {
                navigate("/planning");
                return;
            }
            navigate("/");
        });
    };

    const loadPlannedExpensesDropdown = () => {
        getPlannedExpensesDropdown(selectedBudget, dateFrom, dateTo).then(response => {
            setPlannedExpenses(response);
            if (plannedExpenseBusinessKey !== null) {
                console.log(`plannedExpense: ${findPlannedExpense(response, plannedExpenseBusinessKey)}`);
                setInitialPlannedExpense(findPlannedExpense(response, plannedExpenseBusinessKey));
            }
        })
    };

    const findPlannedExpense = (results: PlannedExpenseDropdownDto[], businessKey: string) => {
        const result = results.find(item => item.businessKey === businessKey);
        if (result === undefined) {
            return null;
        }

        return result;
    };

    useEffect(() => {
        if (selectedBudget !== undefined && selectedBudget !== '') {
            loadPlannedExpensesDropdown()
        }
    }, [selectedBudget]);

    return (
        <div>
            <Grid container spacing={1}>
                <Grid item xs={12} lg={12}>
                    <ContentItem>
                        <Breadcrumbs aria-label="breadcrumb">
                            <Link underline="hover" color="inherit" href="/">
                                Home
                            </Link>
                            <Typography color="text.primary">{t('Create expense')}</Typography>
                        </Breadcrumbs>
                        <h2>{t('Create expense')}</h2>
                        <RealExpenseForm
                            budgetBusinessKey={selectedBudget}
                            onSaveRealExpense={onSaveRealExpense}
                            onSaveExpenseCategory={onSaveExpenseCategory}
                            categories={expenseCategories}
                            plannedExpenses={plannedExpenses}
                            initialPlannedExpense={initialPlannedExpense}
                            loadPlannedExpenses={loadPlannedExpensesDropdown}
                        />
                    </ContentItem>
                </Grid>
            </Grid>
        </div>
    );
}

export default RealExpenseCreate;