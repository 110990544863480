import * as React from 'react';
import {useEffect, useState} from 'react';
import {Drawer, FormControl, InputLabel, Select, Stack} from "@mui/material";
import Button from "@mui/material/Button";
import BudgetDropdown from "../budget-dropdown/BudgetDropdown";
import {addMonths, eachMonthOfInterval, endOfMonth, endOfYear, format, startOfMonth, startOfYear} from "date-fns";
import MenuItem from "@mui/material/MenuItem";
import MonthRangeDto from "../../dto/MonthRangeDto";
import Box from "@mui/material/Box";
import {useTranslation} from "react-i18next";
import FormItem from "../form-item/FormItem";
import useAppContext from "../../hooks/use-app-context";


export default function Filters() {
    const monthPattern = 'MMMM (yyyy)';
    const {t} = useTranslation();
    const [drawerState, setDrawerState] = useState(false);
    const [monthlyRanges, setMonthlyRanges] = useState<MonthRangeDto[]>([]);
    const [selectedMonth, setSelectedMonth] = useState<string>(format(new Date(), monthPattern));
    const {onSetDateFrom, onSetDateTo, budgets} = useAppContext();

    const loadMonthlyRanges = () => {
        const dateNow = new Date();
        const start = startOfYear(dateNow);
        const end = addMonths(endOfYear(dateNow), 2);
        const months = eachMonthOfInterval({start: start, end: end});

        const monthlyRanges = months.map((month) => {
            const name = format(month, monthPattern); // todo: update locale here
            const start = startOfMonth(month).toISOString();
            const end = endOfMonth(month).toISOString();
            return {name, start, end} as MonthRangeDto;
        });

        setMonthlyRanges(monthlyRanges);
    };

    const toggleDrawer =
        (open: boolean) =>
            (event: React.KeyboardEvent | React.MouseEvent) => {
                if (
                    event.type === 'keydown' &&
                    ((event as React.KeyboardEvent).key === 'Tab' ||
                        (event as React.KeyboardEvent).key === 'Shift')
                ) {
                    return;
                }

                setDrawerState(open);
            };

    const getMonthRangeByName = (name: string) => {
        return monthlyRanges.find(item => item.name === name);
    };

    const onChangeSelect = (event: any) => {
        const name = event.target.value;
        setSelectedMonth(name);
        const range = getMonthRangeByName(name);
        if (range) {
            onSetDateFrom(range.start);
            onSetDateTo(range.end);
        }
        setDrawerState(false);
    };

    const isReadyMonthSelect = () => {
        return monthlyRanges.length > 0;
    };

    useEffect(() => {
        loadMonthlyRanges();
    }, []);

    return (
        <div id="date-filter">
            <Button fullWidth onClick={toggleDrawer(true)}>{t('Filters')}</Button>
            <Drawer
                anchor="top"
                open={drawerState}
                onClose={toggleDrawer(false)}
            >
                <Box sx={{width: '100%'}}>
                    <h3>{t('Filters')}:</h3>
                    <Stack spacing={2}>
                        <FormItem>
                            <BudgetDropdown/>
                        </FormItem>
                        <FormItem>
                            {isReadyMonthSelect() && budgets.length > 0 ? <FormControl fullWidth>
                                <InputLabel id="month-select-label">{t('Month')}</InputLabel>
                                <Select
                                    labelId="month-select-label"
                                    id="month-select"
                                    value={selectedMonth}
                                    label={t('Month')}
                                    onChange={onChangeSelect}
                                >
                                    {monthlyRanges.map((month) => (
                                        <MenuItem key={month.name} value={month.name}>{month.name}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl> : null}
                        </FormItem>
                    </Stack>
                </Box>
            </Drawer>
        </div>
    );
};