import * as React from 'react';
import {useEffect, useState} from 'react';
import {Autocomplete, FormControl, TextField} from "@mui/material";
import {getExpenseDescriptions} from "../../api/api";
import {useTranslation} from "react-i18next";
import useAppContext from "../../hooks/use-app-context";

interface Props {
    value: string;
    onSelect: any;
}

export default function ExpenseDescriptionAutocomplete(props: Props) {
    const {t} = useTranslation();
    const {value, onSelect} = props;
    const [descriptions, setDescriptions] = useState<string[]>([]);
    const {selectedBudget} = useAppContext();

    const loadDescriptions = (q: string) => {
        getExpenseDescriptions(selectedBudget, q).then((results) => {
            setDescriptions(results.map(result => result.name));
        });
    };

    const handleInputChange = (val: string) => {
        onSelect(null, val);
        if (val.length >= 3) {
            loadDescriptions(val);
        } else {
            setDescriptions([]);
        }
    };

    const handleEventInputChange = (event: any) => {
        const inputValue = event.target.value;
        handleInputChange(inputValue);
    };

    useEffect(() => {
        handleInputChange(value);
    }, []);

    return (
        <div>
            <FormControl fullWidth>
                <Autocomplete
                    id="description-autocomplete"
                    options={descriptions}
                    onChange={onSelect}
                    autoComplete
                    includeInputInList
                    filterSelectedOptions
                    freeSolo
                    openOnFocus
                    value={value}
                    ListboxProps={{style: {maxHeight: 200}}}
                    renderInput={(params) =>
                        <TextField
                            {...params}
                            label={t('Description')}
                            onChange={handleEventInputChange}
                            autoFocus
                        />}
                />
            </FormControl>
        </div>
    );
};