import * as React from 'react';
import {useEffect, useState} from 'react';
import PageableDto from "../../dto/PageableDto";
import {getPlannings} from "../../api/api";
import {Breadcrumbs, CircularProgress} from "@mui/material";
import {defaultPage, defaultPageSize} from "../../constants/pagination";
import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";
import {useTranslation} from "react-i18next";
import PlanningDto from "../../dto/PlanningDto";
import PlanningList from "../../components/planning-list/PlanningList";
import PlanningAlerts from "../../components/planning-alerts/PlanningAlerts";
import {LoadingItem} from "../../components/loading-item/LoadingItem";
import useAppContext from "../../hooks/use-app-context";

function Planning() {
    const {t} = useTranslation();
    const {selectedBudget, expenseCategories} = useAppContext();
    const emptyPageable = {
        content: [],
        totalPages: 0,
        totalElements: 0,
        size: 0,
        number: 0
    } as PageableDto<PlanningDto[]>;
    const [pageable, setPageable] = useState<PageableDto<PlanningDto[]>>(emptyPageable);
    const [page, setPage] = useState(defaultPage);
    const [pageSize, setPageSize] = useState(defaultPageSize);
    const [loading, setLoading] = useState(true);

    const loadData = async (page: number, size: number) => {
        setLoading(true);
        await getPlannings(selectedBudget, page, size).then(response => {
            setPageable(response);
            setLoading(false);
        });
    };

    useEffect(() => {
        if (selectedBudget !== "") {
            loadData(defaultPage, defaultPageSize);
        }
    }, [selectedBudget]);

    return (
        <div>
            <Breadcrumbs aria-label="breadcrumb">
                <Link underline="hover" color="inherit" href="/">
                    Home
                </Link>
                <Typography color="text.primary">{t('Monthly planning')}</Typography>
            </Breadcrumbs>
            <h1>{t('Monthly planning')}</h1>
            <PlanningAlerts
                budget={selectedBudget}
                pageable={pageable}
                onChange={loadData}
                page={page}
                pageSize={pageSize}
                loading={loading}
            />
            {!loading ? <PlanningList
                pageable={pageable}
                page={page}
                pageSize={pageSize}
                setPage={setPage}
                setPageSize={setPageSize}
                loadData={loadData}
            /> : <LoadingItem><CircularProgress/></LoadingItem>}
        </div>
    );
}

export default Planning;