import * as React from 'react';
import Box from "@mui/material/Box";
import PasswordUpdateForm from "../../components/password-update-form/PasswordUpdateForm";
import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";
import {Breadcrumbs} from "@mui/material";
import {useTranslation} from "react-i18next";
import useAppContext from "../../hooks/use-app-context";

function User() {
    const {t} = useTranslation();
    const {user} = useAppContext();
    return (
        <div>
            <Breadcrumbs aria-label="breadcrumb">
                <Link underline="hover" color="inherit" href="/">
                    Home
                </Link>
                <Typography color="text.primary">{t('User profile')}</Typography>
            </Breadcrumbs>
            <h2>{t('Hello')} {user?.name}</h2>
            <Box sx={{borderBottom: 1, borderColor: 'divider'}}>
                User messages here
            </Box>
            <Box sx={{borderBottom: 1, borderColor: 'divider'}}>
                <PasswordUpdateForm></PasswordUpdateForm>
            </Box>
        </div>
    );
};

export default User;