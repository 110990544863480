import * as React from 'react';
import {useState} from 'react';
import {Alert, Stack, TextField} from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import {useForm} from "react-hook-form";
import {redirect} from "react-router-dom";
import {updatePassword} from "../../api/api";
import UpdatePasswordRequestDto from "../../dto/UpdatePasswordRequestDto";
import {useTranslation} from "react-i18next";
import FormItem from "../form-item/FormItem";
import useAppContext from "../../hooks/use-app-context";


const PasswordUpdateForm = () => {
    const {t} = useTranslation();
    const {register, handleSubmit, formState: {errors}} = useForm();
    const {logout, isOnline} = useAppContext();
    const [newPassword, setNewPassword] = useState('');

    const onNewPasswordChange = (e: any) => setNewPassword(e.target.value);
    const confirmPasswordValidation = (value: string) => {
        return value === newPassword || `${t('Wrong password repeat!')}`;
    };

    const onSubmit = async (data: any) => {
        const updatePasswordRequest = data as UpdatePasswordRequestDto;
        updatePassword(updatePasswordRequest).then(
            () => {
                logout();
                redirect("/");
            }
        );
    };

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <Box sx={{width: '100%'}}>
                <Stack spacing={2}>
                    <FormItem>
                        <h3>{t('Update your password')}:</h3>
                    </FormItem>
                    <FormItem>
                        <TextField
                            fullWidth
                            id="old-password"
                            label={t('Old password')}
                            type="password"
                            {...register("oldPassword", {required: true})}
                            helperText={errors.oldPassword && t('Field is required')}
                        />
                    </FormItem>
                    <FormItem>
                        <TextField
                            fullWidth
                            id="password"
                            label={t('Password')}
                            type="password"
                            value={newPassword}
                            onChange={onNewPasswordChange}
                            helperText={errors.password && t('Field is required')}
                        />
                    </FormItem>
                    <FormItem>
                        <TextField
                            fullWidth
                            id="confirm-passwprd"
                            label={t('Confirm password')}
                            type="password"
                            {...register("confirmPassword", {required: true, validate: confirmPasswordValidation})}
                            helperText={errors.confirmPassword?.type == "required" && t('Field is required')}
                        />
                        {errors.confirmPassword?.type == "validate" ?
                            <Alert severity="error">{t('Fields should be same.')}</Alert> : null}
                    </FormItem>
                    <FormItem>
                        <Button variant="contained" type="submit"
                                disabled={!isOnline}>{t('Submit')}</Button>
                    </FormItem>
                </Stack>
            </Box>
        </form>
    )
}

export default PasswordUpdateForm
