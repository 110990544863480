import * as React from 'react';
import {useEffect, useState} from 'react';
import BudgetForm from "../../components/budget-form/BudgetForm";
import BudgetList from "../../components/budget-list/BudgetList";
import PageableDto from "../../dto/PageableDto";
import BudgetDto from "../../dto/BudgetDto";
import {getBudgetsPaged} from "../../api/api";
import Box from "@mui/material/Box";
import {Breadcrumbs, Tab, Tabs} from "@mui/material";
import {a11yProps} from "../../utils/tab.utils";
import TabPanel from "../../components/tab-panel/TabPanel";
import {defaultPage, defaultPageSize} from "../../constants/pagination";
import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";
import {useTranslation} from "react-i18next";
import useAppContext from "../../hooks/use-app-context";

function Budget() {
    const {t} = useTranslation();
    const {onSaveBudget} = useAppContext();
    const emptyPageable = {
        content: [],
        totalPages: 0,
        totalElements: 0,
        size: 0,
        number: 0
    } as PageableDto<BudgetDto[]>;
    const [pageable, setPageable] = useState<PageableDto<BudgetDto[]>>(emptyPageable);
    const [tabValue, setTabValue] = useState(1);

    const handleOnSaveBudget = (data: any) => {
        onSaveBudget(data).then(() => {
            loadData(defaultPage, defaultPageSize);
            setTabValue(1);
        });
    };

    const handleChangeTab = (event: React.SyntheticEvent, newValue: number) => {
        setTabValue(newValue);
    };

    const loadData = async (page: number, size: number) => {
        await getBudgetsPaged(page, size).then(response => {
            setPageable(response);
        });
    };

    useEffect(() => {
        loadData(defaultPage, defaultPageSize);
    }, []);

    return (
        <div>
            <Breadcrumbs aria-label="breadcrumb">
                <Link underline="hover" color="inherit" href="/">
                    Home
                </Link>
                <Typography color="text.primary">{t('Budgets')}</Typography>
            </Breadcrumbs>
            <h1>{t('Budgets')}</h1>
            <Box sx={{borderBottom: 1, borderColor: 'divider'}}>
                <Tabs value={tabValue} onChange={handleChangeTab} aria-label="tabs">
                    <Tab label={t('Create new')} {...a11yProps(0)} />
                    <Tab label={t('List')} {...a11yProps(1)} />
                </Tabs>
            </Box>
            <TabPanel value={tabValue} index={0}>
                <h2>{t('Create new budget')}</h2>
                <BudgetForm onSaveBudget={handleOnSaveBudget}/>
            </TabPanel>
            <TabPanel value={tabValue} index={1}>
                <h2>{t('Budget list')}</h2>
                <BudgetList pageable={pageable} loadData={loadData}/>
            </TabPanel>
        </div>
    );
}

export default Budget;