import * as React from 'react';
import {Checkbox, Stack, TextField} from "@mui/material";
import LoginRequestDto from "../../dto/LoginRequestDto";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import {useForm} from "react-hook-form";
import {redirect} from "react-router-dom";
import {postLogin} from "../../api/api";
import TokenDto from "../../dto/TokenDto";
import {useTranslation} from "react-i18next";
import FormItem from "../form-item/FormItem";
import useAppContext from "../../hooks/use-app-context";


const LoginForm = () => {
    const {register, handleSubmit, formState: {errors}} = useForm();
    const {login, logout, isOnline} = useAppContext();
    const {t, i18n} = useTranslation();

    const onSubmit = async (data: any) => {
        logout();
        const loginData = data as LoginRequestDto;
        postLogin(loginData).then((tokenDto: TokenDto) => {
            login(tokenDto);
            redirect("/");
        });
    };

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <Box sx={{width: '100%'}}>
                <Stack spacing={2}>
                    <FormItem>
                        <h3>{t('Login')}:</h3>
                    </FormItem>
                    <FormItem>
                        <TextField
                            fullWidth
                            defaultValue="" {...register("username", {required: true})}
                            label={t('Username')}
                            helperText={errors.username && t('Field is required')}
                        />
                    </FormItem>
                    <FormItem>
                        <TextField
                            fullWidth
                            id="outlined-password-input"
                            label={t('Password')}
                            type="password"
                            autoComplete="current-password"
                            {...register("password", {required: true})}
                            helperText={errors.password && t('Field is required')}
                        />
                    </FormItem>
                    <FormItem>
                        <Checkbox
                            inputProps={{'aria-label': 'Remember?'}}
                            {...register("remember", {required: true})}
                            defaultChecked
                        />
                    </FormItem>
                    <FormItem>
                        <Button variant="contained" type="submit" disabled={!isOnline}>{t('Login')}</Button>
                    </FormItem>
                </Stack>
            </Box>
        </form>
    )
}

export default LoginForm
