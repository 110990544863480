import {useEffect} from "react";
import {useNavigate} from "react-router-dom";
import {getUserProgress} from "../../api/api";
import UserProgressDto from "../../dto/UserProgressDto";
import useAppContext from "../../hooks/use-app-context";

const Redirector = () => {
    const navigate = useNavigate();
    const {isOnline, isLoggedIn, appLoading} = useAppContext();

    const checkRedirection = (userProgress: UserProgressDto) => {
        if (userProgress.experience == 0) {
            navigate("/create-first-budget");
            return;
        }

        if (userProgress.experience == 1) {
            navigate("/create-first-revenue");
            return;
        }
    };

    useEffect(() => {
        if (!isOnline || !isLoggedIn || appLoading) {
            return;
        }

        getUserProgress().then(result => checkRedirection(result));
    }, [appLoading, isLoggedIn]);

    return null;
};

export default Redirector;