import * as React from 'react';
import {Backdrop, CircularProgress} from "@mui/material";
import useAppContext from "../../hooks/use-app-context";

export default function AppLoader() {
    const {appLoading} = useAppContext();

    return (
        <Backdrop
            sx={{color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1}}
            open={appLoading}>
            <CircularProgress color="inherit"/>
        </Backdrop>
    );
};
